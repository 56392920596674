.oddin-main {
    padding: 15px
}
.oddin-parent {
    display: grid;
    grid-template-columns: 0.2fr repeat(4, 1fr) 0.2fr;
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 19px;
    grid-row-gap: 10px;
    cursor: pointer;
    font-family: 'Helvetica Neue', Verdana, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    box-shadow: 4px 4px 8px 4px rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 4px;
    max-width: 100%;
}

.oddin-main-title {
    grid-area: 1 / 1 / 2 / 7;
    font-weight: 700;
    font-size: 1.4rem;
    font-weight: 900;
    color: #fff;
    background-color: #ee5d05;
    padding: 1rem;
    text-transform: uppercase;
    text-align: center;
    border-bottom: 1px solid #cbcbd1eb;
    border-radius: 4px 5px 0 0;
    height: 60px;
}

.oddin-bet-title {
    grid-area: 2 / 2 / 3 / 6;
    text-align: center;
    font-size: 18px;
    /* font-weight: bold; */
    font-stretch: semi-condensed;
    color: #000;
    border-bottom: 1px solid #cbcbd1eb;
    padding: 15px;
}

p {
    margin: 0px;
    padding: 0px;
}

.oddin-bet-odd {
    grid-area: 3 / 2 / 3 / 2;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    font-stretch: semi-condensed;
    color: #232324;
    border-right: 1px solid #888894f6;
    display: flex;
    justify-content: center;
    align-items: center;
}

.oddin-cta {
    grid-area: 3 / 5 / 6 / 6;
    justify-content: space-between;
    font-size: 20px;
    font-weight: bold;
    font-stretch: semi-condensed;
    text-transform: uppercase;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 148px;
    height: 50px;
    margin-top: 20px;
    padding-top: 15px;
    color: #fff;
    background: none;
    background-color: #ee5d05;
    border-radius: 4px;
    border: none;
    padding: 0 20px 0 20px;
    cursor: pointer;
    text-decoration: none
}
.oddin-cta.disabled {
    background-color: #ccc;
}

.oddin-tnc {
    grid-area: 4 / 2 / 7 / 6;
    font-size: 14px;
    padding-top: 10px;
    text-align: center;
    border-top: 1px solid #cbcbd1eb;
}
a:hover {
    color: #fff;
    text-decoration: none;
}
