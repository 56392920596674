article {
    max-width: 800px;
}

.banner-combo-wrapper {
    position: relative;
    width: 50%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    background-color: #ee5d05;
    border-radius: 5px;
}

@media (max-width: 900px) {
    .banner-combo-wrapper {
        box-sizing: border-box;
        /* padding: 20px 30px 0 30px; */
        width: 100%;
    }
    .betnow-btn {
        padding-left: 15px !important;
    }
    .modal-dialog {
        display: flex !important;
        justify-content: center;
        align-items: center;
    }
}

.inner-bet-wrapper {
    font-family: "Helvetica Neue", Verdana, Helvetica, Arial, sans-serif;
    max-width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    height: 110px;
    width: 100%;
    position: relative;
    transition-property: transform;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' className='svg' width='500' height='100' rx='5' ry='5' %3E%3Crect className='outterRec' width='500' height='100' rx='5' ry='5' fill='%23ee5d03' x='0' y='0' /%3E%3Crect className='svg-bg' fill='%23232323' rx='5' ry='5' x='7' y='50' width='1000' height='200' transform='rotate(-8)' /%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: cover;
}

@media (max-width: 900px) {
    .inner-bet-wrapper {
        height: 210px;
        flex-direction: column;
    }
}

.bet-odds-inner-combo {
    padding-left: 30px;
    /* flex-basis: 25%; */
    /* padding: 10 0 0 10px; */
}

.bet-odds-inner-combo span {
    display: inline-block;
    font-size: 35px;
    font-weight: bold;
    font-stretch: semi-condensed;
    background-color: #ffffff;
    color: #232324;
    border-radius: 4px;
    padding: 4px 20px;
    transform: rotate(-5deg);
}

@media (max-width: 900px) {
    .bet-odds-inner-combo {
        padding: 0;
        margin-top: 1px;
    }
}

.bet-info {
    flex-basis: 55%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    /* padding-left: 40px; */
}

.bet-info-title {
    font-family: "Helvetica Neue", Verdana, Helvetica, Arial, sans-serif;
    font-size: 18px;
    font-weight: 700;
    color: #ffffff;
}

.bet-info-event {
    font-family: "Helvetica Neue", Verdana, Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: semi-condensed;
    color: #ffffff;
}

.betnow-btn {
    flex-basis: 35%;
    padding-left: 85px;
}

.betnow-btn button {
    box-sizing: border-box;
    font-family: "Helvetica Neue", Verdana, Helvetica, Arial, sans-serif;
    font-size: 22px;
    font-weight: bold;
    font-stretch: semi-condensed;
    line-height: 1;
    text-decoration: none;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 148px;
    height: 50px;
    margin: 0;
    /* padding: 5px 15px;
    padding-left: 156px; */
    text-align: center;
    color: #ffffff;
    background: none;
    background-color: #ee5d05;
    border-radius: 4px;
    border: none;
}

.bet-tag {
    background: #fff;
    width: fit-content;
    color: #222;
    border-radius: 10px;
    text-align: center;
    padding: 5px;
    font-size: 20px;
}

th {
    text-align: center;
    border: none!important;
}

table {
    border: none!important;
}

.total-and-btn {
    display: inline-flex;
}

hr {
    width: 35%;
    border: 1px solid white;
}

.separator {
    font-size: 30px;
}

.table-bordered {
    text-align-last: center;
}
.parent {
    display: grid;
    grid-template-areas:
      "head head"
      "item item"
      "summary summary"
      "foot foot";
    grid-template-columns: repeat(2, 1fr);
    /* grid-template-rows: repeat(6, 1fr); */
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    cursor: pointer;
    font-family: "Helvetica Neue", Verdana, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    box-shadow: 4px 4px 8px 4px rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 4px;
    max-width: 100%;
  }
  .hedaer {
    grid-area: head;
    font-weight: 700;
    font-size: 1.4rem;
    font-weight: 900;
    color: #fff;
    background-color: #ee5d05;
    padding: 1rem;
    text-transform: uppercase;
    text-align: center;
    border-bottom: 1px solid #cbcbd1eb;
    border-radius: 4px 5px 0 0;
  }
  .itemlist {
    grid-area: item;
  }
  .item {
    /* grid-area: item; */
    text-align: center;
    font-size: 18px;
    font-weight: normal;
    font-stretch: semi-condensed;
    color: #000;
    border-bottom: 1px solid #cbcbd1eb;
    padding: 15px;
  }
  p {
    margin: 0px;
    padding: 0px;
  }
  .summary {
    grid-area: summary;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas:
      "time time null btn";
  }
  .time {
    grid-area: time;
    text-align: center;
    font-size: 2.2rem;
    font-weight: bold;
    font-stretch: semi-condensed;
    color: #232324;
    padding: 15px;
  }
  .button {
    grid-area: btn;
    justify-content: space-between;
    font-size: 20px;
    font-weight: bold;
    font-stretch: semi-condensed;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 148px;
    height: 50px;
    margin-top: 10px;
    padding-top: 15px;
    color: #fff;
    background: none;
    background-color: #ee5d05;
    border-radius: 4px;
    border: none;
    padding: 0 20px 0 20px;
    cursor: pointer;
  }
  .footer {
    grid-area: foot;
    font-size: 14px;
    padding-top: 10px;
    text-align: center;
    border-top: 1px solid #cbcbd1eb;
  }