.moneyclockclass1 {
    background-image: url(https://res.cloudinary.com/vendup/image/upload/v1632394797/inline_image_preview_qtfw8n.jpg);
    font-size: 15px;
    margin: auto;
    padding: 15px;
    text-align: center;
    color: #fff;
}
.column2 {
    font-size: 15px;
    width: 100%;
}
.column2 table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}
table.colored tr:nth-child(odd) {
    background-color: #f8f9fa;
    padding: 7px;
}
td, th {
    border: 0 solid #ddd;
    text-align: left;
    padding: 7px;
}
td {
    font-weight: 400;
    vertical-align: top;
}