.AdminView {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 100vh;

  &-header {
    width: 300px !important;
    height: 100% !important;
    background-color: #343a40!important;

    h4 {
      font-size: 16px;
      font-weight: bold;
      padding: 15px 0px;
      margin: 0px 15px;
      border-bottom: 1px solid #0001;
    }

    a {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 7px 8px;
      padding: 10px 13px;
      color: #fff !important;
      text-decoration: none;
      border-radius: 5px;
      background: #0001;
      font-weight: bold;
      color: #0001;
      font-size: 14px;

      &.active {
        background-color: #ec5b00 !important;
        color: #fff !important;
      }
    }
  }

  &-view {
    flex: 1;
    height: 100vh;
    overflow-y: scroll;
  }
}
