.left-Login {
    background-color: #232323;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    display: flex;
    box-sizing: border-box;
    flex: 1;
    min-height: 100%;
  }
  
  @media only screen and (max-width: 575px) {
    .left-Login {
      padding-top: 5vh;
    }
  }
  
  .right-Login {
    display: flex;
    box-sizing: border-box;
    flex: 1;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    background: linear-gradient(to right, #232323 , #fd630100), url('../../../image/bg2.jpg');
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .logo_right {
    width: 300px;
    height: 300px;
    /* box-shadow: 0px 0px 10px 0px #fff; */
  }
  
  @media only screen and (max-width: 643px) {
    .right-Login {
      display: none;
    }
  }
  
  .login-header {
    color: #ff9c5d;
    margin-bottom: 44px;
    font-size: 100px;
    letter-spacing: 5px;
    line-height: 1.1;
    white-space: pre-line;
  }
  
  .form {
    flex-direction: column;
    display: flex;
    width: 100%;
    max-width: 485px;
    margin-top: 70px;
    box-sizing: border-box;
    padding: 0px 30px 50px;
  }
  
  .input_wrapper {
    margin-bottom: 27px;
    box-sizing: border-box;
  }
  
  .label_wrapper {
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
    display: flex;
    box-sizing: border-box;
  }
  
  .label {
    color: rgb(122,124,131);
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    white-space: pre-line;
  }
  
  .inputbox_wrapper {
    position: relative;
    box-sizing: border-box;
  }
  
  .input {
    box-shadow: rgb(31,31,31) 0px 0px 3px 1px inset;
    background-color: #444;
    color: rgb(255 255 255);
    display: block;
    font-size: 14px;
    font-weight: 400;
    height: 60px;
    line-height: normal;
    padding-left: 24px;
    padding-right: 24px;
    width: 100%;
    -webkit-appearance: none;
    box-sizing: border-box;
    outline-color: transparent;
    outline-offset: 4px;
    outline-style: none;
    outline-width: 1px;
    transition-duration: 150ms;
    transition-property: all;
    transition-timing-function: ease-in-out;
    border-radius: 4px;
    border-width: 0px;
  }
  
  .input:hover {
    background-color: #333;
  }
  
  .input:focus,
  .input:active {
    box-shadow: rgb(110, 120, 152) 0px 0px 0px 2px inset,
      rgba(110, 120, 152, 0.18) 0px 0px 0px 4px;
  }
  
  .label_forgot_pass_wrapper {
    background-image: linear-gradient(
      94deg,
      rgb(87, 34, 255) 12%,
      rgb(198, 3, 255) 86%
    );
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .label_forgot_pass {
    margin-bottom: 8px;
    line-height: 16px;
    font-size: 14px;
    font-weight: 500;
    white-space: pre-line;
  }
  
  .password_toggler {
    position: absolute;
    top: 17px;
    right: 20px;
    box-sizing: border-box;
  }
  
  .password_toggler_icon_wrapper {
    color: rgb(110, 120, 152);
    cursor: pointer;
    opacity: 0.6;
    display: block;
    transition-duration: 150ms;
    transition-timing-function: ease-in-out;
    text-decoration: none;
  }
  
  .password_toggler_icon {
    font-size: 20px;
    stroke: rgb(110, 120, 152);
  }
  
  .Login_button {
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    height: 70px;
    line-height: 1;
    padding-left: 48px;
    padding-right: 48px;
    width: 100%;
    -webkit-appearance: none;
    box-sizing: border-box;
    cursor: pointer;
    outline-style: none;
    text-align: center;
    transition-duration: 200ms;
    user-select: none;
    white-space: nowrap;
    box-shadow: rgba(31, 42, 75, 0.11) 0px 5px 10px;
    color: white;
    border-radius: 4px;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    text-decoration: none;
    background: linear-gradient(60.4deg, #fc3f00, #fd6301, #fc3f00);
  }
  
  .Login_button:hover {
    transform: translateY(-3px);
  }
  
  .or_login_wrapper {
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    box-sizing: border-box;
  }
  
  .or_login_line {
    background-color: rgb(222, 226, 239);
    display: flex;
    height: 1px;
    box-sizing: border-box;
    flex: 1 1 0%;
  }
  
  .or_login_text {
    margin-left: 10px;
    margin-right: 10px;
    white-space: pre-line;
    color: rgb(110, 120, 152);
  }
  
  .log_with_other {
    max-width: 400px;
    box-shadow: rgba(31, 42, 75, 0.11) 0px 5px 10px;
    background: linear-gradient(
      317.9deg,
      #4031c4, #4051b5
    );
    color: rgb(255, 255, 255);
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    height: 70px;
    letter-spacing: 0px;
    line-height: 1;
    min-width: 100%;
    padding-left: 44px;
    padding-right: 44px;
    margin-bottom: 38px;
    text-transform: none;
    width: 100%;
    -webkit-appearance: none;
    box-sizing: border-box;
    cursor: pointer;
    outline-style: none;
    text-align: center;
    transition-duration: 200ms;
    user-select: none;
    white-space: nowrap;
    border-radius: 5px;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    text-decoration: none;
  }
  
  .logo_navabr {
    width: 50px;
    /* margin: 10px 0 -10px -60px; */
    position: absolute;
    top: 5%;
    left: 3.5%;
    /* display: none; */
  }

  .logo_front{
    width: 20%;
    /* margin-bottom:  10px; */
  }

  .error-msg{
    margin-top: 15px;
    color: #f00;
    letter-spacing: 0.2em;
  }
  
  @media only screen and (max-width: 1366px) {
    .logo_navabr {
      position: absolute;
      top: 7%;
      left: 4%;
    }
  }
  
  @media only screen and (max-width: 1280px) {
    .logo_navabr {
      /* margin: 10px 0 -3px 0px; */
      top: 5.5%;
      left: 4%;
    }
  }
  
  @media only screen and (max-width: 991px) {
    .logo_navabr {
      left: 3%;
      top: 4%;
      width: 50px;
    }
  }
  
  @media only screen and (max-width: 768px) {
    .logo_navabr {
      left: 4%;
      top: 3%;
    }
  }
  
  @media only screen and (max-width: 643px) {
    .logo_navabr {
      /* margin: 10px 0 -5px -18%; */
      top: 3%;
      display: block;
    }
  }
  
  @media only screen and (max-width: 500px) {
    .logo_navabr {
      /* margin: 10px 0 -3px 0; */
      left: 6%;
    }
  }
  