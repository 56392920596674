specific-class {
  width: 900px;
}
@media only screen and (max-width: 768px) {
  .specific-class {
    width: 90vw !important;
  }
}

.modal-dialog-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 900px;
}

/*  */
.btn-logout {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-outline-light {
  background-color: #0000;
  border-color: #fd6301;
  color: #fd6301;
}

.btn-primary {
  background-color: #fd6301 !important;
  border-color: #fd6301 !important;
  box-shadow: none;
  outline: none;
  color: #fff;
}

.btn-outline-light:hover,
.btn-outline-light:active,
.btn-outline-light:focus {
  background-color: #fd6301;
  border-color: #fd6301;
  color: #fff;
}

.btn-primary:hover {
  background-color: #ec5b00;
  border-color: #ec5b00;
  color: #fff;
}

.mainWrapper {
  min-height: 100vh;
  background-color: #0e0e0e;
}

.top-input {
  color: #fff;
  min-width: 150px;
  margin: 10px 20px;
  height: 35px;
  background-color: #0000;
  border: none;
  outline: none;
  box-shadow: none;
  border-color: #fff;
  border-bottom-width: 1px;
  border-bottom: 1px solid #fff;
  padding: 15px 10px;
}

.btn-colasable {
}

.btn-colasable h5 {
  color: #f0f8ffbd;
  font-weight: lighter;
  font-size: 14px;
}

.btn-accordian {
  box-shadow: 0px -1px 3px -1px #fff;
  height: 50px;
}

@media only screen and (max-width: 575px) {
  .btn-accordian {
    height: 60px;
  }
}

.btn-colasable p {
  color: #fd6301;
  font-weight: lighter;
  font-size: 14px;
  text-align: right !important;
}

.btn-accordian-inner {
  margin: 15px 15px 15px 15px;
  padding: 10px;
  background-color: #fd6301;
  border-radius: 5px;
  color: #fff;
  /* box-shadow: 0px 1px 3px -1px #000; */
  cursor: pointer;
}

.modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-description {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 20px;
  font-size: 18px;
  align-items: flex-start;
}

img.logo_dashb {
  max-width: 1.5%;
  margin-right: 8px;
}

.form-cust {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@media only screen and (max-width: 540px) {
  .form-cust {
    flex-direction: column;
    align-items: flex-start;
  }
}

.top-input-drop {
  width: 100%;
  margin: 10px 20px;
  padding: 4px;
  background-color: #0000;
  border: 0px;
  border-bottom: 1px solid #ffff;
  color: #fff;
}

.top-btn {
  margin: 10px 20px;
}

.modal-content.specific-class {
  width: 950px;
}

@font-face {
  font-family: 'ComicRunes';
  src: local('ComicRunes'), url(fonts/ComicRunes.otf) format('opentype');
}

#runic-title {
  font-family: ComicRunes, "ComicRunes";
}

a {
  color: #fff;
}
.custom-control-input:checked~.custom-control-label::before{
  border-color: #fd6301 !important;
  background-color: #fd6301 !important;
}

.top-input-dark {
  width: 100%;
  margin: 10px 20px;
  padding: 4px;
  border: 0px;
  border-bottom: 1px solid #495057;
  color: #495057;
}